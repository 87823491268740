@keyframes scroll {
  0% {
    transform: translateX(1100px);
  }
  100% {
    transform: translateX(calc(-410px * 6));
  }
}
.slider {
  background: none;
  height: 150px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  background-color: #14141f;
}
.slider::before,
.slider::after {
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slideTrack {
  animation: scroll 20s linear infinite;
  display: flex;
  width: calc(150px);
}
.slider .slide {
  padding-left: 150px;
  align-self: center;
}

.imgCarr {
  max-width: 100px;
  max-height: 100px;
  margin: auto;
}

.imgCarrPlayback {
  max-width: 70px;
  max-height: 70px;
  margin: auto;
}

.title {
  font-size: 34px;
  color: #14141f;
  filter: brightness(1);
  background: linear-gradient(-45deg, #e6ddd2, #866d4b, #e6ddd2, #866d4b);
  background-size: 100% 100%;
  -webkit-background-clip: text;
  -webkit-text-stroke: 3px transparent;
  -webkit-text-fill-color: #14141f;
}

.carouselTitle {
  text-align: center;
  padding: 50px;
}

.carrousel {
  overflow: hidden;
}

@media only screen and (max-width: 400px) {
  .carouselTitle {
    padding: 20px;
  }
}
