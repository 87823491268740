.overlayWrapper {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  top: 0px;
  left: 0px;
  background-color: rgba(42, 42, 60, 0.6);
  z-index: 1002;
}

.wrapper {
  padding: 6px;
  display: flex;
  position: relative;
  max-width: 50%;
  min-height: 200px;
  height: min-content;
  flex-direction: row;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  margin-top: 130px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.close {
  position: absolute;
  width: 5px;
  height: 5px;
  top: 20px;
  right: 50px;
  background: transparent;
  border: none;
  padding: unset;
  margin: unset;
  cursor: pointer;
}

.close:hover {
  box-shadow: none !important;
}

.socialListContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.socialListContainer li {
  margin: 10px;
}
.socialListContainer i:hover {
  cursor: pointer;
}

.contactContainer {
  background-color: grey;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-self: center;
}
